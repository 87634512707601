import ApiService from "@/core/services/api.service.js";

const authorService = {
    getAuthors(param) {
        var paramStr = ''
        if(param != undefined){
          if(param.page != undefined){
            paramStr = '?page='+(param.page+1)
          }else{
            paramStr = '?'
          }
          for (const key in param.filters) {
            if(param.filters[key] != null)
              paramStr += '&' + key + '=' + param.filters[key]
          }
        }
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/blog/authors"+paramStr)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

    getAuthorById(id) {
        return  new Promise((resolve, reject) => {
          ApiService.get("api/admin/blog/author/detail/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    createAuthor(data) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/blog/author/create",data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    updateAuthor(data,id) {
        return  new Promise((resolve, reject) => {
          ApiService.post("api/admin/blog/author/update/"+id,data)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },
    deleteAuthor(id) {
        return  new Promise((resolve, reject) => {
          ApiService.delete("api/admin/blog/author/delete/"+id)
            .then(function(response) {
              if (response.data) {
                resolve(response.data);
              } else {
                resolve("");
              }
            })
            .catch(function(error) {
              reject(error)
            });
        });
    },

}

export default authorService;